import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import {
    audience,
    clientId,
    domain
} from '../auth_config.json'
import App from './App.vue'
import './assets/tailwind.css'
import {
    Auth0Plugin
} from './auth'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  useRefreshTokens: true,
  cacheLocation: 'localstorage', // valid values are: 'memory' or 'localstorage'
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location
      .pathname)
  },
})

new Vue({
  router,
  store,
  vuetify,
  pinia,
  render: h => h(App),
}).$mount('#app')
