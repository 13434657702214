const market = [
  {
    path: '/market/watchlist',
    name: 'market-watch-list',
    component: () => import('@/views/market/watchList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/molecules',
    name: 'market-molecules',
    component: () => import('@/views/market/molecules.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/marketOverview',
    name: 'market-market-over-view',
    component: () => import('@/views/market/marketOverview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/singleView',
    name: 'market-single-view',
    component: () => import('@/views/market/singleView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/moleculeList',
    name: 'market-molecule-list',
    component: () => import('@/views/market/moleculeList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/totalpermoleculeList',
    name: 'market-total-molecule-list',
    component: () => import('@/views/market/totalsPerMolecule.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/market/pharmacyview',
    name: 'market-pharmacy-view',
    component: () => import('@/views/market/pharmacyView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default market
