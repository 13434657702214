/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import { authGuard } from '@/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import actions from './routes/actions'
import charts from './routes/charts'
import forms from './routes/forms'
import market from './routes/market'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter: authGuard,
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
    children: [
      ...market,
      ...actions,
      ...forms,
      ...charts,
    ],
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
