import {
    mdiChartLine, mdiDesktopMacDashboard, mdiPill
} from '@mdi/js'

export default [{
  subheader: 'Dashboards',
}, {
  title: 'Overview',
  icon: mdiChartLine,
  to: 'market-watch-list',
},
{
  title: 'Dispensing Metrics',
  icon: mdiDesktopMacDashboard,
  to: 'market-market-over-view',
},
{
  title: 'Insurance Overview',
  icon: mdiPill,
  to: 'market-molecules',
},

]
