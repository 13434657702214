/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * It takes a number, multiplies it by 100, converts it to an integer, divides it by 100, and returns
 * the result.
 * @param number - The number you want to round.
 * @returns the rounded number.
 */
function round(number) {
  const roundNumber = (parseInt((number) * 100, 10) / 100)

  return roundNumber
}

export default round