const sos = [
  {
    path: '/sos/ratestable',
    name: 'sos-rates-table',
    component: () => import('@/views/sos/ratesTable.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default sos
