/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * return a number converted to a currency
 * @param {number} number
 * @returns a currency formatted string
 *
 */

function currencyConvert(number) {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return dollarUS.format(number)
}

export default currencyConvert