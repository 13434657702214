import {
    mdiStorePlus
} from '@mdi/js'

export default [{
  subheader: 'Forms',
}, {
  title: 'Prescription Template',
  icon: mdiStorePlus,
  to: 'market-pharmacy-view',
},
{
  title: 'Doctor Template',
  icon: mdiStorePlus,
  to: 'market-pharmacy-view',
},
]
