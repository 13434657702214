/**
 * returns window width and height
 * Import windowAttributes  import { windowAttributes } from '@/util/functions/functionsData'

 * @returns returns window width and height
 */

function handleResize() {
  const windowAttributes = {}
  windowAttributes.width = window.innerWidth
  windowAttributes.height = window.innerHeight

  return windowAttributes
}

export default handleResize
