/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'

export const useFilterStore = defineStore('FilterStore', {
  state: () => ({
    dateRange: [],
    accNumbers: [],
    groupCode: [],
    mfr: [],
  }),

  getters: {
    getDateRange: state => state.dateRange,
    getAccNum: state => state.accNumbers,
    getGroupCode: state => state.groupCode,
    getMfr: state => state.mfr,
  },

  actions: {
    setDateRange(date) {
      this.dateRange = date
    },
    setAccNumbers(accNumbers) {
      this.accNumbers = accNumbers
    },
    setGroupCode(groupCode) {
      this.groupCode = groupCode
    },
    setMfr(mfr) {
      this.mfr = mfr
    },
    refreshCharts() {},
  },
})
