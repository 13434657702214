<template>
  <div
    v-if="$auth.user[`https://api.pharmaguide.ca/claims/app_metadata`].role === 'superadmin'"
    :key="chartKey"
  >
    <v-row class="tw-pt-4">
      <v-col
        v-if="dateState"
        cols="6"
        md="3"
      >
        <date-picker @input="updateFilter($event)"></date-picker>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-card>
          <v-select
            v-model="selectedPharmacies"
            :disabled="!pharmacyState"
            dense
            outlined
            :items="pharmacyListData"
            item-text="pharmacyName"
            item-value="accNumber"
            height="40"
            :menu-props="{
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
              offsetY: true,
              offsetOverflow: true,
              transition: false
            }"
            label="Select Pharmacies"
            multiple
            persistent-hint
            hide-details
            clearable
            @change="fetchPharmacyList"
          ></v-select>
        </v-card>
      </v-col>
      <v-col

        cols="6"
        md="3"
      >
        <v-card>
          <v-autocomplete
            v-model="molecule"
            :disabled="!moleculeState"
            :items="moleculeListData"
            :append-icon="icons.mdiMagnify"
            item-text="genericName"
            item-value="groupCode"
            item-color="primary"
            label="Molecule Search"
            multiple
            dense
            height="40"
            outlined
            hide-details
            :menu-props="{
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
              offsetY: true,
              offsetOverflow: true,
              transition: false
            }"
            clearable
            @change="fetchPharmacyList"
          ></v-autocomplete>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-card>
          <v-autocomplete
            v-model="mfr"
            :disabled="!mfrState"
            :items="mfrListData"
            :append-icon="icons.mdiMagnify"
            item-text="group"
            item-value="group"
            item-color="primary"
            label="Mfr Search"
            multiple
            dense
            height="40"
            outlined
            hide-details
            :menu-props="{
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
              offsetY: true,
              offsetOverflow: true,
              transition: false
            }"
            clearable
            @change="fetchPharmacyList"
          ></v-autocomplete>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiAlertCircleOutline } from '@mdi/js'
import { debounce } from 'lodash'
import { windowAttributes } from '@/util/functions/functionsData'
import { handleResize } from '@/util/functions'
import datePicker from '@/components/filters/datePicker.vue'
import { chartService, apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'AppBarFilter',
  components: {
    datePicker,
  },
  setup() {
    const selectedPharmacies = ref([])
    const filterStore = useFilterStore()

    return {
      selectedPharmacies,
      filterStore,
    }
  },
  data() {
    return {
      dateState: true,
      pharmacyState: true,
      moleculeState: true,
      mfrState: true,
      chartKey: 0,
      molecule: [],
      mfr: [],
      cardHeight: 'auto',
      fullHeight: 'auto',
      windowAttributes,
      handleResize,
      dateRange: [],
      pharmacyListData: [],
      moleculeListData: [],
      mfrListData: [],
      icons: {
        mdiMagnify,
        mdiAlertCircleOutline,
      },
    }
  },
  watch: {
    // eslint-disable-next-line space-before-function-paren
    // eslint-disable-next-line quote-props
    $route: {
      handler(to) {
        this.moleculeState = to.name !== 'market-watch-list'
        if (to.name === 'market-watch-list') {
          this.moleculeState = false
        } else if (to.name === 'market-market-over-view') {
          this.moleculeState = false
        } else if (to.name === 'home') {
          this.moleculeState = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)
    this.$root.$off('filter-molecule')
  },
  async mounted() {
    this.$root.$on('refresh-bar', body => {
      this.refreshBar(body)
    })
    await this.fetchPharmacyList()
    await this.matchHeight()
  },
  methods: {
    async fetchPharmacyList() {
      //   this.filterStore.setDateRange(this.dateRange)
      this.filterStore.setAccNumbers(this.selectedPharmacies)
      this.filterStore.setGroupCode(this.molecule)
      this.filterStore.setMfr(this.mfr)

      this.filterStore.refreshCharts()
      this.$root.$emit('refresh-charts')

      //   this.chartKey += 1

      if (!this.pharmacyListData.length || !this.moleculeListData.length || !this.mfrListData.length) {
        const token = await this.$auth.getTokenSilently()
        const pharmList = chartService.getChart(token, 'pharmacyListSelect')
        const molList = chartService.getChart(token, 'moleculeList')
        const mfrList = apiService.getData(token, 'mfrList')
        const [pharmacyListData, mfrListData] = await Promise.all([pharmList, mfrList])
        this.pharmacyListData = pharmacyListData
        this.mfrListData = mfrListData
        molList.then(moleculeListData => {
          this.moleculeListData = moleculeListData
        })
      }
    },
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 150).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
    async updateFilter(date) {
      this.filterStore.setDateRange(date)

      //   this.filterStore.setAccNumbers(this.selectedPharmacies)
      //   this.filterStore.setGroupCode(this.molecule)
      //   this.filterStore.setMfr(this.mfr)
      this.filterStore.refreshCharts()
      this.$root.$emit('refresh-charts')
    },
    refreshBar(body) {
      //   this.groupCode = [...body]
      this.molecule = body
      console.log('refreshBar', body)
    },
  },
}
</script>
