import axios from 'axios'

let api = process.env.VUE_APP_CHART_API

// const debug = process.env.VUE_APP_DEBUG

const debug = true

if (process.env.NODE_ENV === 'development' && debug === true) {
  api = 'http://localhost:3001'
}

const getSanofiData = async (accessToken, query, body) => {
  try {
    const res = await axios.post(
      `${api}/sanofi/?agg=${query}`, {
        ...body,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

export default {
  getSanofiData,
}
