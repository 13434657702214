const actions = [
  {
    path: '/action/hitList',
    name: 'action-hit-list',
    component: () => import('@/views/actions/hitList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default actions
