<template>
  <v-row>
    <v-col cols="12">
      <VueCtkDateTimePicker
        id="date-time-picker"
        v-model="dateRange"
        label="Select Date Range"
        :max-date="currentDate"
        formatted="ll"
        range
        format="YYYY-MM-DD"
        :dark="isDark"
        @input="sortDateRange"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  name: 'DateRangePickerDialog',
  components: {
    VueCtkDateTimePicker,
  },
  props: {},
  setup() {
    const { isDark } = useAppConfig()

    return { isDark }
  },
  data() {
    return {
      dateRange: {
        end: moment().format('YYYY-MM-DD'),
        start: moment().subtract(6, 'month').format('YYYY-MM-DD'),
      },
      sortedDateFilter: [],
      currentDate: moment().format('YYYY-MM-DD'),
    }
  },
  methods: {
    async sortDateRange() {
      this.sortedDateFilter = [this.dateRange.start, this.dateRange.end]
      await this.$emit('input', this.sortedDateFilter)
    },
  },
}
</script>
