// import actions from './actions'
// import charts from './charts'
import forms from './forms'
import sanofi from './sanofi'

// import tables from './tables'

export default [
//   {
//     title: 'Home',
//     icon: mdiHomeOutline,
//     to: 'home',
//   },

  ...sanofi,
  ...forms,

//   ...tables,
//   ...actions,
//   ...charts,
]
